import React, { useState } from 'react'
import { withSession } from '../_context/session.context'
import { UserService } from '../_service';

/**
 * Component to update user name before joining the meeting
 * @param {*} param0 
 * @returns 
 */
function NameUpdateComponent({ user, setUser }) {

    const [name, setName] = useState(user.name ? user.name : "");

    return (
        <div className="LostPage bg-black text-white h-screen flex justify-center items-center flex-col">
            <div className="w-64">
                <div className="text-xs mb-2">Update your name</div>
                <input type="text"
                    className="px-4 py-2 rounded border bg-transparent border-gray-50 w-full mb-4"
                    placeholder="Enter Name"
                    value={name}
                    onChange={e => {
                        setName(e.target.value);
                    }} />
                <button className="bg-blue-500 text-white py-2 px-4 rounded"
                    onClick={e => {
                        // check if name valid
                        if (!name || name === "New User") {
                            return alert("Please use a valid name!");
                        }
                        //  update user name
                        UserService.updateProfile(user, { name })
                            .then(({ user }) => {
                                setUser(user);
                            })
                            .catch(error => {
                                alert("Could not update name!");
                                console.log({ error });
                            });
                    }}>Join Meeting</button>
            </div>
        </div>
    )
}

const NameUpdate = withSession(NameUpdateComponent);

export default NameUpdate;
