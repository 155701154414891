import { Route, Router, Switch } from 'react-router';
import './App.css';
import JoinMeeting, { JOIN_MEETING_ROUTE } from './_components/join-meeting.page';
import LostPage from './_components/lost.page';
import MeetingPage, { MEETING_PAGE_ROUTE } from './_components/meeting.page';
import { history } from './_config/history.config';
import { SessionProvider } from './_context/session.context';

function App() {
  return (
    <div className="App">
      <SessionProvider>
        <Router history={history}>
          <Switch>
            <Route path={JOIN_MEETING_ROUTE} component={JoinMeeting} exact />
            <Route path={MEETING_PAGE_ROUTE} component={MeetingPage} exact />
            <Route path="" component={LostPage} />
          </Switch>
        </Router>
      </SessionProvider>
    </div>
  );
}

export default App;
