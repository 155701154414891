const base = process.env.REACT_APP_BASE_URL;

const endpoints = {
    auth: {
        loginWithOTP: base + "login/otp",
        verifyOTP: base + "verify-otp",
        googleLoginV2: base + "auth/google/v2"
    },
    goLive: base + "dyte/go-live",
    community: {
        create: base + "community",
        updateCommunity: id => base + "community/" + id,
        addressAvailability: address => base + 'community/address-availability/' + address,
        updateLogo: id => base + "community/" + id + "/update-logo",
        getCommunity: address => base + "community/" + address,
        getCommunityById: id => base + "community-by-id/" + id,
        joinCommunity: id => base + "community/join/" + id,
        getUserPosts: (communityId, userId, page) => base + `community/${communityId}/user/${userId}/posts?page=${page}`,
        groups: id => base + "community/" + id + "/groups",
        subscribers: (communityId, groupId) => base + "community/" + communityId + "/subscribers?group=" + groupId,
        links: id => base + "community/" + id + "/links",
        addNewLink: id => base + "community/" + id + "/links",
        editLink: (id, linkId) => base + "community/" + id + "/links/" + linkId,
        deleteLink: (id, linkId) => base + "community/" + id + "/links/" + linkId,
    },
    group: {
        all: base + "group",
        my: base + "group/my",
        byMe: base + "group/by-me",
        updateGroup: groupId => base + "group/" + groupId,
        deleteGroup: groupId => base + "group/" + groupId,
        uploadBanner: groupId => base + "group/" + groupId + "/banner",
        addTab: groupId => base + "group/" + groupId + "/tab",
        editTab: (groupId, tabId) => base + "group/" + groupId + "/tab/" + tabId,
        deleteTab: (groupId, tabId) => base + "group/" + groupId + "/tab/" + tabId,
        goLive: groupId => base + "dyte/start-meeting/" + groupId,
        members: groupId => base + "group/" + groupId + "/members",
        updateRole: (groupId, userId) => base + "group/" + groupId + "/members/" + userId + "/update-role",
        removeUser: (groupId, userId) => base + "group/" + groupId + "/remove-user/" + userId,
        posts: groupId => base + "group/" + groupId + "/posts",
        acceptInvitation: groupId => base + "group/" + groupId + "/accept-invite",
        join: groupId => base + "group/" + groupId + "/join",
        acceptJoinRequest: (groupId, userId) => base + "group/" + groupId + "/join-request/" + userId + "/accept",
        rejectJoinRequest: (groupId, userId) => base + "group/" + groupId + "/join-request/" + userId + "/reject",
        createOrder: groupId => base + "payment/group/" + groupId + "/create-order",
        verifyPayment: (groupId, paymentId) => base + "payment/group/" + groupId + "/verify-payment/" + paymentId,
    },
    post: {
        create: base + "post",
        update: postId => base + "post/" + postId,
        uploadImage: postId => base + "post/" + postId + "/images",
        uploadVideo: postId => base + "post/" + postId + "/videos",
        uploadDocument: postId => base + "post/" + postId + "/document",
        paginated: page => base + "post-paginated?page=" + page,
        popularPaginated: page => base + "post/explore-paginated?page=" + page,
        detail: postId => base + "post/" + postId,
        pin: postId => base + "post/" + postId + "/pin",
        unpin: postId => base + "post/" + postId + "/unpin",
        addComment: postId => base + "post/" + postId + "/comment",
        uploadImagesToComment: (postId, commentId) => base + "post/" + postId + "/comment/" + commentId + "/images",
        uploadDocumentToComment: (postId, commentId) => base + "post/" + postId + "/comment/" + commentId + "/document",
        deleteComment: (postId, commentId) => base + "post/" + postId + "/comment/" + commentId,
        like: postId => base + "post/" + postId + "/like",
        vote: postId => base + "post/" + postId + "/vote",
        dislike: postId => base + "post/" + postId + "/dislike",
        delete: postId => base + "post/" + postId,
        getMeetingData: meetingId => base + "dyte/join-meeting/" + meetingId + "/json",
        toggleMeetingRecording: (roomName, endingKey) => base + "dyte/meeting/" + roomName + "/toggle-recording/" + endingKey,
        endMeeting: (roomName, endingKey) => base + "dyte/meeting/" + roomName + "/end/" + endingKey,
    },
    user: {
        syncOneSignalWebUserId: base + "user/save-one-signal-web-user-id",
        profile: base + "profile",
        profileById: userId => base + "user/" + userId,
        updateProfile: base + "profile",
        updatePicture: base + "profile/picture",
        getAllUsers: base + "user",
        followUser: userId => base + "user/" + userId + "/follow",
    },
    misc: {
        uploadImage: base + "upload-image"
    },
    open: {
        getPost: postId => base + "open/post/" + postId,
        getPostList: (groupId, tabId) => base + "open/group/" + groupId + "/feed/" + tabId + "/posts",
    }
};

export { endpoints };