import axios from "axios"
import { endpoints } from "../_config"

class DyteService {

    /**
     * Get the groups by user
     * @param {User} user 
     */
    static async goLive(groupId, user, tabId = null, title = null) {
        return axios.post(groupId
            ? endpoints.group.goLive(groupId)
            : endpoints.goLive,
            {
                tabId,
                title
            }, {
            headers: {
                Authorization: "Bearer " + user.token
            }
        }).then(response => response.data);
    }

    /**
     * Get meeting data for meetingId
     * @param {*} user 
     * @param {*} meetingId 
     * @returns 
     */
    static async getMeetingData(user, meetingId, userName) {
        return axios.get(endpoints.post.getMeetingData(meetingId) + "?userName=" + userName, {
            headers: {
                Authorization: "Bearer " + user.token
            }
        }).then(response => response.data);
    }

    static async toggleMeetingRecording(roomName, endingKey) {
        return axios.post(endpoints.post.toggleMeetingRecording(roomName, endingKey), {}, {}).then(response => response.data);
    }

    static async endTheMeeting(roomName, endingKey) {
        return axios.get(endpoints.post.endMeeting(roomName, endingKey), {}).then(response => response.data);
    }
}

export { DyteService }