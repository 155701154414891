import React, { useState } from 'react';

// create context
const SessionContext = React.createContext(null);

// create provider
export const SessionProvider = props => {

    const userStringified = localStorage.getItem("pensil-meeting.user");

    const [user, updateUser] = useState(JSON.parse(userStringified));

    const setUser = user => {
        localStorage.setItem('pensil-meeting.user', JSON.stringify(user));
        updateUser(user);
    }

    return (
        <SessionContext.Provider value={{
            user,
            setUser
        }}>
            {props.children}
        </SessionContext.Provider>
    );
}

// create consumer
export const withSession = Component => props => (
    <SessionContext.Consumer>
        {
            controls => <Component {...props} {...controls} />
        }
    </SessionContext.Consumer>
)